import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function fetchcorpse(pageNumber = 1) {
  return axios.get(`${baseURL}/corpse?page=${pageNumber}?status=${false}`, {
    headers: header,
  });
}

export function firststep(payload) {
  return axios.post(
    `${baseURL}/corpse/stepone`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function secondstep(payload) {
  return axios.post(
    `${baseURL}/corpse/create/steptwo`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function thirdstep(payload) {
  return axios.post(
    `${baseURL}/corpse/create/stepthree`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function forthstep(payload) {
  return axios.post(
    `${baseURL}/corpse/create/stepfour`,
    { ...payload },
    { headers: header }
  );
}

export function createembalment(payload) {
  return axios.post(
    `${baseURL}/corpse/embalment/create`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function billingInvoice(payload) {
  return axios.post(
    `${baseURL}/corpse/create/biling/invoice`,
    { ...payload },
    { headers: header }
  );
}

export function fetchFridge(page_number = 0) {
  return axios.get(`${baseURL}/fridge?status=${page_number}`, {
    headers: header,
  });
}

export function createFridge(payload) {
  return axios.post(
    `${baseURL}/fridge/create`,
    { ...payload },
    { headers: header }
  );
}

export function getCorpse(number) {
  return axios.post(
    `${baseURL}/corpse-release/search?search_text=${number}`,
    {},
    {
      headers: header,
    }
  );
}

export function createRelease(payload) {
  return axios.post(
    `${baseURL}/corpse-release/create`,
    { ...payload },
    {
      headers: header,
    }
  );
}

export function payForItem(payload) {
  return axios.post(
    `${baseURL}/payments/create`,
    { ...payload },
    { headers: header }
  );
}

export function partPayForItem(payload) {
  return axios.post(
    `${baseURL}/payments/part/create`,
    { ...payload },
    { headers: header }
  );
}
