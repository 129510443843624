<template>
  <div class="flex overflow-hidden">
    <div class="py-8 pr-7 w-full">
      <div class="flex justify-between items-center">
        <h1 class="text-2xl font-bold">Fridge</h1>
        <button
          @click="addNewFridge = true"
          class="bg-primary py-4 px-7 rounded-xl shadow-lg flex items-center font-bold text-white"
        >
          <plus class="mr-5" />
          Add Fridge
        </button>
      </div>
      <div class="" v-if="items">
        <fridge-table
          :allItems="items"
          :perPage="perPage"
          :total="total"
          :currentPage="currentPage"
        />
      </div>
      <div class="h-full w-full flex items-center justify-center" v-else>
        <Spinner />
      </div>
    </div>
    <add-new-fridge-modal
      v-if="addNewFridge"
      @created="(addNewFridge = false), fetchFridges()"
    />
  </div>
</template>

<script>
import { fetchFridge } from "@/services/addmission";

export default {
  name: "Fridge",
  components: {
    plus: () => import("@/assets/icons/PlusIcon.vue"),
    FridgeTable: () => import("@/components/Inventory/FridgeTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
    AddNewFridgeModal: () =>
      import("@/components/Inventory/AddNewFridgeModal.vue"),
  },
  data() {
    return {
      searchTerm: "",
      items: [],
      perPage: 0,
      currentPage: 0,
      total: 0,
      addNewFridge: false,
      filter: {
        start_date: "",
        end_date: "",
        branch: "",
      },
    };
  },
  methods: {
    async fetchFridges(page) {
      try {
        const fridge = await fetchFridge(page);
        this.items = fridge.data.fridge;
        this.currentPage = fridge.data.fridge.current_page;
        this.perPage = fridge.data.fridge.per_page;
        this.total = fridge.data.fridge.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchFridges();
  },
};
</script>
